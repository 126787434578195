<template>
  <div class="inner-wrapper">
    <h2 v-if="cms.loaded" class="title title-underline title-offset animation-underline">
      {{ cms.page.fgWelcomeBack }} {{ authStore.userDetails.firstName }}!
    </h2>
    <p class="text">{{ cms.page.fgThankYouMessage }}</p>
    <div class="nav-bar">
      <template v-for="(navBar, index) in computedNavBars" :key="index">
        <router-link :to="navBar.url" class="nav-bar-item">
          <div class="nav-bar-body">
            <i class="icons" v-bind:class="navBar.icon"></i>
            <span class="nav-bar-name">{{ navBar.name }}</span>
            <p class="nav-bar-text">{{ navBar.text }}</p>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import {mapStores} from 'pinia'
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"
import inView from 'in-view'

export default {
  name: "Friend",
  data() {
    return {
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "friend",
          MinistryUpdates: false,
        },
        page: {},
      },
      navBars: [
        {
          url: "/ministry-update",
          name: "Ministry Updates",
          text: "Learn about the latest ministry impact, lives changed, and prayer opportunities.",
          icon: "icons-ministry-updates",
        },
        {
          url: "/prayer",
          name: "Prayer",
          text: "Read more about what God is doing through the Association.",
          icon: "icons-prayer",
        },
        {
          url: "/giving",
          name: "Give",
          text: "Donate to further equip Gideons to share God’s Word.",
          icon: "icons-give",
        },
        {
          url: "/witness",
          name: "Witness",
          text: "Learn tips for sharing your faith with others.",
          icon: "icons-witness",
        },
        {
          url: "/store",
          name: "Store",
          text: "Purchase Testaments and other witnessing resources to help you share Christ with others.",
          icon: "icons-store",
        },
        {
          url: "/faqs",
          name: "FAQs",
          text: "Answers to some Frequently Asked Questions.",
          icon: "icons-faqs",
        },
        {
          url: "/learn-about-gideons",
          name: "Learn About Gideons",
          text: "Read more about what God is doing through the Association.",
          icon: "icons-learn-about-gideons",
        },
        {
          url: "/invite-others",
          name: "Invite Others",
          text: "Send an invitation to others who might be interested in becoming a Friend.",
          icon: "icons-invite-others",
        },
        {
          url: "/account-information",
          name: "My Account",
          text: "Manage your contact information.",
          icon: "icons-my-account",
        },
      ],
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore),
    computedNavBars() {
      if (this.authStore.isUSAMember) return this.navBars
      else {
        return this.navBars.filter(bar => bar.name !== 'Store' && bar.url !== '/store')
      }

    },
  },
  async created() {
    window.addEventListener("scroll", this.handleScroll);
    this.setContentLoaded(false)
    this.setTitlePageFromCms()
    await this.fetchCmsData()
  },
  methods: {
    setAllCmsComponentContent(componentContents) {
      this.cms.comp = componentContents;
    },
    setAllCmsContent(cmsContent) {
      this.setNavBarsContent(cmsContent);
      this.setTitlePageFromCms(cmsContent)
    },
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setAllCmsContent(results);
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    prepareVeriableNameToMatchSitecoreName(name) {
      return {
        text: `fg${name.replace(/\s+/g, "")}Text`,
        name: `fg${name.replace(/\s+/g, "")}Name`,
      };
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },

    setNavBarsContent(cmsContent) {
      this.navBars = this.navBars.map((item) => {
        let sitecoreName = this.prepareVeriableNameToMatchSitecoreName(
            item.name
        );
        item.name = cmsContent[sitecoreName.name];
        item.text = cmsContent[sitecoreName.text];
        return item;
      });
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgFriendHome ? page.fgFriendHome : 'Friend Home'
      const pageSubtitle = page && page.fgWelcomeToMyAccount ? page.fgWelcomeToMyAccount : 'Welcome to your friend account!'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "bg-friend",
        btn_history: false,
      });
    },
    handleScroll() {
      inView('.title-underline')
          .on('enter', el => {
            el.classList.add('inview');
          })
          .on('exit', el => {
            el.classList.remove('inview');
          });

      inView('.nav-bar')
          .on('enter', el => {
            el.classList.add('inview');
          })
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>


<style lang="scss">
.section-offset .inner-wrapper {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px;

  @media screen and (max-width: $sm) {
    padding: 50px 25px;
  }

  p {
    font-weight: 400;
  }
}

.nav-bar {
  &-item {
    opacity: 0;
    position: relative;
    top: 15px;
    transition: all 1s ease;;

    &:hover {
      .nav-bar {
        &-body {
          background: $blue-darker;
          transition-delay: 0s;
          box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
        }

        &-name,
        &-text {
          color: white;
        }
      }

      .icons {
        filter: brightness(0) invert(1);
      }
    }
  }

  &-body {
    background-color: white;
    box-shadow: 0 10px 25px rgba(#000, 0.3);
    height: 100%;
    transition: all 0.33s ease;
  }

  &-name {
    display: block;
    font-weight: 700;
    color: $blue-darker;
    text-transform: uppercase;
    margin: 20px 0 10px;
    text-align: center;
    transition: color 0.25s;
  }

  &-text {
    font-size: 15px;
    line-height: 25px;
    // font-weight: 700;
    color: $grey-darkest;
    text-align: center;
    transition: color 0.25s;
  }

  &.inview {
    .nav-bar-item {
      top: 0;
      opacity: 1;
      @for $i from 1 through 9 {
        &:nth-child(#{$i}) {
          transition-delay: calc((1s / 9) * $i);
        }
      }
    }
  }
}

.icons {
  width: 47px;
  height: 40px;
  display: flex;
  background-position: 50%;
  background-repeat: no-repeat;
  margin: 0 auto;
  transition: filter 0.25s;

  &-ministry-updates {
    background-image: url(../assets/images/icons-ministry-updates.svg);
  }

  &-prayer {
    background-image: url(../assets/images/icons-prayer.svg);
  }

  &-give {
    background-image: url(../assets/images/icons-give.svg);
  }

  &-store {
    background-image: url(../assets/images/icons-store.svg);
  }

  &-witness {
    background-image: url(../assets/images/icons-witness.svg);
  }

  &-faqs {
    background-image: url(../assets/images/icons-faqs.svg);
  }

  &-learn-about-gideons {
    background-image: url(../assets/images/Gideons_logo-primary.png);
  }

  &-invite-others {
    background-image: url(../assets/images/icons-invite-others.svg);
  }

  &-my-account {
    background-image: url(../assets/images/icons-my-account.svg);
  }
}

@media screen and (min-width: 991px) {
  .nav-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 50px 0;

    &-body {
      padding: 50px 45px 35px;
    }

    &-item {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      padding: 14px 20px;
    }

    &-name {
      font-size: 16px;
      line-height: 35px;
    }
  }
}

@media screen and (max-width: 991px) {
  .nav-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 51px 0px -14px;

    &-item {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 10px 9px;

      &:last-child {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &-text {
      display: none;
    }

    &-body {
      padding: 37px 15px 26px;
    }

    &-name {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>